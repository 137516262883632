import React, { useState } from "react";
import Button from "./uiComponents/button.tsx";
import logo from "./static/logo.png";
import { IoMdMenu } from "react-icons/io";
import { IoMdClose } from "react-icons/io";

const NavBar = () => {
  const [activeSection, setActiveSection] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleSetActive = (section) => {
    setActiveSection(section);
    setIsMenuOpen(false); // Close menu when a link is clicked
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const linkClasses = (section) => {
    return `relative inline-block font-semibold text-navy dark:text-navy
      ${
        activeSection === section
          ? "before:absolute before:bottom-0.5 before:start-0 before:w-full before:h-1 before:bg-seafoam"
          : ""
      }
      hover:text-navy/50 dark:hover:text-navy/50 transition-all duration-200 ${
        isMenuOpen ? "text-lg" : "text-base"
      }`;
  };

  return (
    <div>
      <header className="flex fixed backdrop-blur-xl bg-white bg-opacity-40 flex-wrap md:justify-start md:flex-nowrap z-50 w-full py-1">
        <nav
          className="relative max-w-7xl w-full flex flex-wrap md:grid md:grid-cols-12 basis-full items-center px-4 md:px-6 md:px-8 mx-auto"
          aria-label="Global"
        >
          <div className="md:col-span-3">
            <a
              className="flex-none rounded-xl text-xl inline-block font-semibold focus:outline-none focus:opacity-80"
              href="../templates/creative-agency/index.html"
              aria-label="Preline"
            >
              <img src={logo} className="h-16 w-18" alt="Logo"></img>
            </a>
          </div>

          <div className="flex items-center gap-x-2 ms-auto py-1 md:ps-6 md:order-3 md:col-span-3">
            <a
              href="#book"
              className="px-4 py-3 lg:px-8 inline-flex items-center gap-x-2 text-sm font-medium rounded-xl border border-transparent bg-navy text-white hover:bg-navy/80 transition disabled:opacity-50 disabled:pointer-events-none focus:outline-none"
            >
              Free consultation
            </a>
            <div className="md:hidden">
              <button
                type="button"
                className="flex justify-center items-center text-sm font-semibold rounded-md border border-gray-200 text-navy hover:bg-gray-100 dark:text-navy dark:border-neutral-700 dark:hover:bg-neutral-700"
                onClick={toggleMenu}
                aria-controls="navbar-collapse-with-animation"
                aria-label="Toggle navigation"
              >
                {isMenuOpen ? (
                  <IoMdClose className="w-8 h-8 transition-transform duration-300" />
                ) : (
                  <IoMdMenu className="w-8 h-8 transition-transform duration-300" />
                )}
              </button>
            </div>
          </div>

          <div
            id="navbar-collapse-with-animation"
            className={`transition-transform duration-300 ease-in-out md:block md:w-auto md:basis-auto md:order-2 md:col-span-6 absolute md:relative top-full left-0 w-full md:top-auto md:left-auto transform ${
              isMenuOpen ? "translate-x-0" : "-translate-x-full"
            } md:translate-x-0 bg-white overflow-y-hidden h-screen md:h-full p-6 md:bg-transparent`}
          >
            <div className="flex flex-col gap-y-4 gap-x-0 mt-5 md:flex-row md:justify-center md:items-center md:gap-y-0 md:gap-x-7 md:mt-0">
              <div className="">
                <a
                  className={linkClasses("services")}
                  href="#services"
                  onClick={() => handleSetActive("services")}
                  aria-current="page"
                >
                  Services
                </a>
              </div>
              <div>
                <a
                  className={linkClasses("about")}
                  href="#about"
                  onClick={() => handleSetActive("about")}
                >
                  About Us
                </a>
              </div>
              <div>
                <a
                  className={linkClasses("team")}
                  href="#team"
                  onClick={() => handleSetActive("team")}
                >
                  Team
                </a>
              </div>
              <div>
                <a
                  className={linkClasses("reviews")}
                  href="#reviews"
                  onClick={() => handleSetActive("reviews")}
                >
                  Reviews
                </a>
              </div>
              <div>
                <a
                  className={linkClasses("chat")}
                  href="#book"
                  onClick={() => handleSetActive("chat")}
                >
                  Let's Chat
                </a>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default NavBar;
