import "./App.css";
import NavBar from "./components/header.tsx";
import Hero from "./components/hero.tsx";
import Services from "./components/services.tsx";
import About from "./components/about.tsx";
import ServiceDescriptor from "./components/serviceDecription.tsx";
import Stats from "./components/stats.tsx";
import Team from "./components/team.tsx";
import Testimonials from "./components/testimonials.tsx";
import Contact from "./components/contact.tsx";
import CalendlyEmbed from "./components/CalendlyEmbed.tsx";
import Footer from "./components/footer.tsx";
import { Analytics } from "@vercel/analytics/react";

function App() {
  return (
    <div className="App">
      <NavBar />
      <div className="">
        <Hero />
        <Services />
        <ServiceDescriptor />
        <Stats />
        <About />
        <Team />
        <Testimonials />
        <CalendlyEmbed url="https://calendly.com/koby-brand-surge" />
        <Footer />
        <Analytics />
      </div>
    </div>
  );
}

export default App;
