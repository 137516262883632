// src/components/CalendlyEmbed.tsx
import React, { useEffect, useState } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

interface CalendlyEmbedProps {
  url: string;
}

const loadCalendlyScript = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const scriptId = "calendly-script";
    const existingScript = document.getElementById(scriptId);
    if (existingScript) {
      resolve();
      return;
    }
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.id = scriptId;
    script.type = "text/javascript";
    script.async = true;
    script.onload = () => {
      resolve();
    };
    script.onerror = () => {
      reject(new Error("Failed to load Calendly script."));
    };
    document.body.appendChild(script);
  });
};

const CalendlyEmbed: React.FC<CalendlyEmbedProps> = ({ url }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      const loadAndInitCalendly = async () => {
        try {
          await loadCalendlyScript();
          const parentElement = document.getElementById(
            "calendly-inline-widget"
          ) as HTMLElement;
          if (window.Calendly && parentElement) {
            window.Calendly.initInlineWidget({
              url,
              parentElement,
            });
          } else {
            console.error("Calendly script is not available.");
          }
        } catch (error) {
          console.error("Error loading Calendly script:", error);
        }
      };

      loadAndInitCalendly();
    }
  }, [url, isModalOpen]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div
      id="book"
      className="mx-auto max-w-screen-xl flex flex-col items-center px-6 py-10 bg-gray-100 rounded-md "
    >
      <div className="max-w-2xl flex flex-col gap-4">
        <h2 className="text-3xl text-navy font-bold lg:text-5xl text-center">
          Let's{" "}
          <span className="underline decoration-wavy decoration-seafoam">
            Chat
          </span>
        </h2>
        <p className="mt-3 mb-8 text-gray-600 text-center">
          Ready to take your digital presence to the next level? Schedule a free
          consultation with our experts. Use our convenient booking calendar to
          find a time that works for you, and let’s discuss how we can help your
          business grow.
        </p>
        <div className="mx-auto">
          <button
            onClick={openModal}
            className="bg-navy text-white py-2 px-8 rounded-lg hover:bg-navy/80 flex items-center gap-6"
          >
            Book a Consultation <FaCalendarAlt color="#69C1B1" />
          </button>
        </div>
      </div>

      {isModalOpen && (
        <div className="z-50 fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 p-6 ">
          <div className="bg-white rounded-lg shadow-lg max-w-3xl  w-full p-6 relative">
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-gray-700 hover:text-gray-900"
            >
              <IoMdClose className="fill-navy w-8 h-8" />
            </button>
            <div
              id="calendly-inline-widget"
              className="w-full sm:h-[40em] h-[30em] "
              style={{ minHeight: "400px" }}
            >
              {/* Inline widget will be injected here */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendlyEmbed;
