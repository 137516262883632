import React from "react";
import koby from "./static/koby.png";
import keegan from "./static/keegan.jpg";
import chaney from "./static/chaney.jpg";

const team = () => {
  return (
    <div id="team">
      <div className="max-w-screen-xl px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
          <h2 className="text-5xl font-bold md:text-5xl text-navy">
            Our{" "}
            <span className="underline decoration-wavy decoration-seafoam">
              Team
            </span>
          </h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12">
          <div className="flex items-center flex-col text-center">
            <div className="relative rounded-md border-2 border-seafoam shadow-md h-60 sm:h-64 overflow-hidden w-48 sm:size-48 lg:size-60 hover:h-56 hover:shadow-xl transition-all duration-300">
              <img
                className="absolute inset-0 h-full w-full object-cover"
                src={keegan}
                alt="Image Description"
              ></img>
            </div>
            <div className="mt-2 sm:mt-4">
              <h3 className="text-sm font-medium text-palegreen sm:text-base lg:text-lg">
                Keegan Tingle
              </h3>
              <p className="text-xs text-gray-600 sm:text-sm lg:text-base">
                Partner
              </p>
            </div>
          </div>

          <div className="flex items-center flex-col text-center">
            <div className="relative rounded-md border-2 border-seafoam shadow-md h-60 sm:h-64 overflow-hidden w-48 sm:size-48 lg:size-60 hover:h-56 hover:shadow-xl transition-all duration-300">
              <img
                className="absolute inset-0 h-full w-full object-cover"
                src={koby}
                alt="Image Description"
              ></img>
            </div>
            <div className="mt-2 sm:mt-4">
              <h3 className="text-sm font-medium text-palegreen sm:text-base lg:text-lg">
                Koby Batts
              </h3>
              <p className="text-xs text-gray-600 sm:text-sm lg:text-base">
                Partner
              </p>
            </div>
          </div>

          <div className="flex items-center flex-col text-center">
            <div className="relative rounded-md border-2 border-seafoam shadow-md h-60 sm:h-64 overflow-hidden w-48 sm:size-48 lg:size-60 hover:h-56 hover:shadow-xl transition-all duration-300">
              <img
                className="absolute inset-0 h-full w-full object-cover"
                src={chaney}
                alt="Image Description"
              ></img>
            </div>
            <div className="mt-2 sm:mt-4">
              <h3 className="text-sm font-medium text-palegreen sm:text-base lg:text-lg">
                Chaney Guffey
              </h3>
              <p className="text-xs text-gray-600 sm:text-sm lg:text-base">
                Director of Social Media Affairs
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default team;
