import React from "react";
import TeamPhoto from "./static/brandsurge-image1.jpg";

const about = () => {
  return (
    <div>
      <section id="about">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
          <div className="max-w-lg">
            <h2 className="text-3xl text-navy font-bold sm:text-5xl">
              The Team Behind Your{" "}
              <span className="underline decoration-wavy decoration-seafoam">
                {" "}
                Online Growth
              </span>
            </h2>
          </div>

          <div className="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
            <div className="relative rounded-md border-2 border-seafoam shadow-md h-64 overflow-hidden sm:h-72 lg:h-full hover:h-80 hover:shadow-xl transition-all duration-300">
              <img
                alt=""
                src={TeamPhoto}
                className="absolute inset-0 h-full w-full object-cover"
              />
            </div>

            <div className="lg:py-16">
              <article className="space-y-4 text-gray-600">
                <p>
                  At Brand Surge, our mission is to empower startups and small
                  businesses to navigate the digital world with confidence and
                  innovation.
                </p>

                <p>
                  We are committed to providing customized, cutting-edge digital
                  strategies that drive growth, enhance online presence, and
                  foster sustainable success. By leveraging the latest
                  technologies and data-driven insights, we strive to transform
                  our clients' digital challenges into op as opportunities,
                  ensuring they thrive in a competitive digital landscape.
                </p>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default about;
