import React from "react";
import Button from "./uiComponents/button.tsx";
import { FaSearch } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { MdAdsClick } from "react-icons/md";
import { BiSolidBookContent } from "react-icons/bi";
import { FaCode } from "react-icons/fa6";
import { MdOutlineMail } from "react-icons/md";

const services = () => {
  return (
    <div>
      <section id="services" className="bg-white text-navy">
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-10">
          <div className="mx-auto max-w-xl text-center">
            <h2 className="text-3xl font-bold sm:text-5xl">
              Kickstart your{" "}
              <span className="underline decoration-wavy decoration-seafoam">
                marketing
              </span>
            </h2>

            <p className="mt-4 text-gray-600">
              Explore our cutting-edge services: intuitive interfaces, real-time
              analytics, and seamless integrations, all designed to elevate your
              business and drive success.
            </p>
          </div>

          <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
            <a
              className="block rounded-md border ring-navy ring-1 p-8 shadow-xl transition hover:ring-navy/60 hover:shadow-palegreen/20"
              href="#"
            >
              <FaSearch className="h-8 w-8 fill-palegreen" />

              <h2 className="mt-4 text-xl font-bold text-navy">
                Search Engine Optimization
              </h2>

              <p className="mt-1 text-sm text-gray-600">
                Enhance your clients’ visibility in search engine results with
                tailored SEO strategies, including keyword research, on-page
                optimization, link building, and technical SEO audits.
              </p>
            </a>

            <a
              className="block rounded-md border ring-navy ring-1 p-8 shadow-xl transition hover:ring-navy/60 hover:shadow-palegreen/20"
              href="#"
            >
              <FaFacebook className="h-8 w-8 fill-palegreen" />
              <h2 className="mt-4 text-xl font-bold text-navy">
                Social Media Marketing
              </h2>

              <p className="mt-1 text-sm text-gray-600">
                Develop and manage custom social media strategies that improve
                engagement, build community, and increase brand visibility
                across platforms such as Facebook, Instagram, LinkedIn, and
                Twitter.
              </p>
            </a>

            <a
              className="block rounded-md border ring-navy ring-1 p-8 shadow-xl transition hover:ring-navy/60 hover:shadow-palegreen/20"
              href="#"
            >
              <MdAdsClick className="h-8 w-8 fill-palegreen" />
              <h2 className="mt-4 text-xl font-bold text-navy">
                Pay-Per-Click Advertising
              </h2>

              <p className="mt-1 text-sm text-gray-600">
                Create and manage PPC campaigns, including Google Ads and social
                media ads, that drive targeted traffic to your clients’
                websites, optimizing for the highest return on investment.
              </p>
            </a>

            <a
              className="block rounded-md border ring-navy ring-1 p-8 shadow-xl transition hover:ring-navy/60 hover:shadow-palegreen/20"
              href="#"
            >
              <BiSolidBookContent className="w-8 h-8 fill-palegreen" />
              <h2 className="mt-4 text-xl font-bold text-navy">
                Reputation Management
              </h2>

              <p className="mt-1 text-sm text-gray-600">
                Monitor and manage your online reputations to ensure positive
                brand perception, addressing negative reviews and feedback
                proactively and enhancing positive engagements and testimonials.
              </p>
            </a>

            <a
              className="block rounded-md border ring-navy ring-1 p-8 shadow-xl transition hover:ring-navy/60 hover:shadow-palegreen/20"
              href="#"
            >
              <FaCode className="w-8 h-8 fill-palegreen" />

              <h2 className="mt-4 text-xl font-bold text-navy">
                Web Design and Development
              </h2>

              <p className="mt-1 text-sm text-gray-600">
                Offer comprehensive web design and development services to
                create visually appealing, user-friendly websites that are
                optimized for performance and conversions.
              </p>
            </a>

            <a
              className="block rounded-md border ring-navy ring-1 p-8 shadow-xl transition hover:ring-navy/60 hover:shadow-palegreen/20"
              href="#"
            >
              <MdOutlineMail className="h-8 w-8 fill-palegreen" />

              <h2 className="mt-4 text-xl font-bold text-navy">
                Email Marketing
              </h2>

              <p className="mt-1 text-sm text-gray-600">
                Provide email marketing services that include campaign strategy,
                template design, list management, A/B testing, and analytics to
                engage subscribers and drive conversions.
              </p>
            </a>
          </div>

          <div className="mt-12 text-center">
            <Button Text="Get Started" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default services;
