import React from "react";

const stats = () => {
  return (
    <div>
      <div className="max-w-screen-xl px-4 py-8 sm:px-6 lg:px-8 lg:py-10 mx-auto">
        <div className="grid gap-6 grid-cols-1 sm:gap-12 lg:grid-cols-3 lg:gap-20 ">
          <div className="bg-gray-100 p-6 rounded-md hover:ring-2 hover:shadow-xl ring-navy transition-all duration-300">
            <h4 className="text-lg sm:text-xl font-semibold text-gray-800">
              Increase in Web Traffic
            </h4>
            <p className="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-navy">
              150%
            </p>
            <p className="mt-1 text-gray-500">from SEO campaigns</p>
          </div>

          <div className="bg-gray-100 p-6 rounded-md hover:ring-2 hover:shadow-xl ring-navy transition-all duration-300">
            <h4 className="text-lg sm:text-xl font-semibold text-gray-800">
              Leads Generated
            </h4>
            <p className="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-palegreen">
              5000+
            </p>
            <p className="mt-1 text-gray-500">From Marketing campaigns</p>
          </div>

          <div className="bg-gray-100 p-6 rounded-md hover:ring-2 hover:shadow-xl ring-navy transition-all duration-300">
            <h4 className="text-lg sm:text-xl font-semibold text-gray-800">
              Ad Spend ROI
            </h4>
            <p className="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-navy">
              4x
            </p>
            <p className="mt-1 text-gray-500">from PPC campaigns</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default stats;
