import React from "react";
import Button from "./uiComponents/button.tsx";
import { FaLongArrowAltRight } from "react-icons/fa";
import dashboard from "./static/dashboard.png";

const hero = () => {
  return (
    <div>
      <section>
        <div className="max-w-screen-xl mx-auto px-4 pt-28 pb-10 sm:py-28 gap-12 text-gray-600 md:px-8">
          <div className="space-y-5 max-w-4xl mx-auto text-center">
            <h2 className="font-bold leading-none text-navy text-5xl max-w-lg lg:max-w-4xl sm:text-6xl lg:text-[5em] mx-auto">
              Empowering Your{" "}
              <span className="sm:underline sm:decoration-wavy sm:decoration-seafoam">
                Digital{" "}
                <span className="underline decoration-wavy decoration-seafoam">
                  Journey
                </span>
              </span>
            </h2>

            <p className="max-w-2xl mx-auto">
              Brand Surge offers innovative Solutions to Propel Your Business
              Toward Sustainable Online Growth and Success
            </p>
            <div className="items-center justify-center gap-x-3 space-y-3 sm:flex sm:space-y-0">
              <a
                href="#book"
                className="block py-2.5 px-4 text-white font-medium bg-navy duration-150 hover:bg-navy/80 rounded-lg"
              >
                Get In Touch
              </a>
              <a
                href="#services"
                className="block py-2.5 px-4 text-navy hover:bg-gray-300 hover:text-navy/80 font-medium duration-300 active:bg-gray-100 border border-gray-300 rounded-lg"
              >
                Our Services
              </a>
            </div>
          </div>
          <div className="mt-14">
            <div className="p-4 border rounded-xl border-navy/50">
              <img
                src={dashboard}
                className="w-full shadow-xl rounded-lg"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default hero;
