import React from "react";

const Button = ({ Text }) => {
  return (
    <div>
      <button className="py-3 px-8 inline-flex items-center gap-x-2 text-sm font-medium rounded-xl border border-transparent bg-navy text-white hover:bg-navy/80 transition disabled:opacity-50 disabled:pointer-events-none focus:outline-none">
        {Text}
      </button>
    </div>
  );
};

export default Button;
