import React from "react";
import Button from "./uiComponents/button.tsx";
import { FaCode } from "react-icons/fa";
import { FaSearchDollar } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";

const serviceDecription = () => {
  return (
    <div>
      <div className="max-w-screen-xl px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="grid md:grid-cols-2 gap-12">
          <div className="lg:w-3/4 ">
            <h2 className="text-3xl text-navy font-bold lg:text-5xl ">
              Our Top{" "}
              <span className="underline decoration-wavy decoration-seafoam">
                Services
              </span>
            </h2>
            <p className="mt-3 mb-8 text-gray-800 ">
              Explore our range of expert services designed to drive your
              business success. From crafting impactful email marketing
              campaigns and generating high-quality leads to building dynamic,
              user-friendly websites, we offer comprehensive solutions tailored
              to meet your digital marketing needs.
            </p>
            <Button Text="Get Started" />
          </div>

          <div className="space-y-6 lg:space-y-10">
            <div className="flex">
              <span className="flex-shrink-0 inline-flex justify-center items-center size-[46px] rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm mx-auto ">
                <FaCode className="h-6 w-6 fill-navy" />
              </span>
              <div className="ms-5 sm:ms-8">
                <h3 className="text-base sm:text-lg font-semibold text-gray-800 ">
                  Website Development
                </h3>
                <p className="mt-1 text-gray-600 ">
                  Transform your online presence with our expert website
                  development. We build responsive, user-friendly websites that
                  reflect your brand and enhance your digital footprint.
                </p>
              </div>
            </div>

            <div className="flex">
              <span className="flex-shrink-0 inline-flex justify-center items-center size-[46px] rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm mx-auto ">
                <FaSearchDollar className="h-6 w-6 fill-navy" />
              </span>
              <div className="ms-5 sm:ms-8">
                <h3 className="text-base sm:text-lg font-semibold text-gray-800 ">
                  Lead Generation
                </h3>
                <p className="mt-1 text-gray-600 ">
                  Boost your business growth with our lead generation
                  strategies. We create targeted campaigns that attract,
                  capture, and convert high-quality leads into loyal customers.
                </p>
              </div>
            </div>

            <div className="flex">
              <span className="flex-shrink-0 inline-flex justify-center items-center size-[46px] rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm mx-auto ">
                <MdOutlineMail className="h-6 w-6 fill-navy" />
              </span>
              <div className="ms-5 sm:ms-8">
                <h3 className="text-base sm:text-lg font-semibold text-gray-800 ">
                  Email Marketing
                </h3>
                <p className="mt-1 text-gray-600 ">
                  Drive engagement and nurture your audience with our tailored
                  email marketing campaigns. From compelling content to
                  automation, we ensure your message reaches the right people at
                  the right time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default serviceDecription;
